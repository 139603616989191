@font-face {
    font-family: "PPMori";
    src: url("../fonts/PPMori-Regular.otf") format('truetype');
}
  
.services-container{
    background-color: #edf7eb;
    font-family: 'Oswald', sans-serif;
    padding: 7vmin 0;
    border-bottom-left-radius: 150px 60px;
    border-bottom-right-radius: 150px 60px;
}

.services-container span{
    font-weight: bold;
    font-size: 2.5vmin;
    color: black;
}

.services-flexbox{
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 5vmin;
    padding-right: 5vmin;
}

.services-container img{
    width: 9vmin;
}

.treatments, .color, .lightening{
    background-color: #e593b1;
    box-shadow: 0px 0.5vmin 1vmin 0vmin rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10%;
    padding: 3vmin;
}

.circle-image{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
    border: 0.1vmin solid #000000;

    height: 13vmin;
    width: 13vmin;
    margin-bottom: 3vmin;
}

@media only screen and (max-height: 400px) {
    .services-flexbox{
        justify-content: center;
    }
    .treatments, .color, .lightening{
        padding-left: 8vmin;
    }
}
