.footer-background{
    background-color: #212121; 
    padding: 4vmin;
    font-family: 'Oswald', sans-serif;
}

.footer-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.footer-container span, .footer-container a{
    color: whitesmoke;
    font-size: 2.3vmin;
    margin: 1vmin;
}

a.footer-link{
    text-decoration: none;
}

.column{
    display: flex;
    flex-direction: column;
}

a.web-design{
    display: flex;
    justify-content: center;
    padding-top: 3vmin;
    color: #c0c0c0;
    font-size: 2vmin;
    text-decoration: none;
}